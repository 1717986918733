import { Cart } from 'src/Elements/Cart'
import {
  CartItemDataIdentifier,
  ProductId,
  PriceId,
  UpdatableItemType,
  VariantId,
  OrderId,
  OrderNumber,
  LineItemId,
} from 'src/Elements/Cart/types'
import { cleanEmptyObjectKeys } from 'src/Elements/Utils/general'
import { listenKeys } from 'nanostores'
import { getSelectedVariant } from 'src/Elements/Order/AddToCart/addToCart'

type OnMountCallback = (pcc: ProductCardComponent) => void

export type UpdatableItem = {
  variantName: string
  priceName: string
  direction: string
  cardDetails: {
    variantId: VariantId
    priceId: PriceId
    orderId?: OrderId
    lineItemId?: LineItemId
  }
}

export type UpdatableOrder = {
  id: OrderId
  number: OrderNumber
  type: UpdatableItemType
  updatableItems?: UpdatableItem[]
}

export type ExistingOrderProductCardDetail = {
  updatableOrders: UpdatableOrder[]
}

type ProductCardComponent = {
  // TODO: Move to CardComponent
  id: string
  element: HTMLElement
  render: () => void

  selectedOrderIndex: number
  selectedUpdatableCartItemIndex: number

  renderType: UpdatableItemType

  variantId: VariantId
  priceId: PriceId

  lineItemId: LineItemId
  orderId: OrderId

  selectType: string
  quantity: number

  isChecked: boolean

  // TODO: define these types
  product: any
  variant: any
  selected_price: any
  variantValues: any
  valuesPositions: any

  skipMountOnRender?: boolean
} & ExistingOrderProductCardDetail

export type UpdatablePccState = Partial<
  {
    renderType: UpdatableItemType
    variantId: VariantId
    priceId: PriceId

    selectedOrderIndex: number
    selectedUpdatableCartItemIndex?: number

    selectType: string
    quantity: number

    // Cart impacted changes:
    cartItemRenderType: UpdatableItemType
    cartItemLineItemId: LineItemId
    cartItemOrderId: OrderId
  } & ExistingOrderProductCardDetail
>

export const registerProductEventListeners = (pcc: ProductCardComponent): void => {
  registerUpdatableOrders(pcc)
  registerVariantEventListeners(pcc)
}

export const updateCardByProductIdState = (productId: ProductId, data: UpdatablePccState): void => {
  const oldState: UpdatablePccState = globalThis.Checkout.store.productCardByProductId.get()[productId]
  const newState: UpdatablePccState = cleanEmptyObjectKeys({ ...oldState, ...data })
  updateCart(productId, oldState, newState)
  globalThis.Checkout.store.productCardByProductId.setKey(productId, newState)
}

export const registerOneTimeCardElementListeners = (
  productCardComponents: ProductCardComponent[],
  onMountCallback: OnMountCallback
): void => {
  productCardComponents.forEach((pcc) => {
    const anyUpdatableOrders = globalThis.Checkout.computed.anyUpdatableOrders.get()
    const initialPccState = globalThis.Checkout.store.productCardByProductId.get()[pcc.product.id]
    renderPcc(pcc, initialPccState, anyUpdatableOrders, onMountCallback)
    listenKeys(globalThis.Checkout.store.productCardByProductId, [pcc.product.id], () => {
      const newPccState = globalThis.Checkout.store.productCardByProductId.get()[pcc.product.id]
      const anyUpdatableOrders = globalThis.Checkout.computed.anyUpdatableOrders.get()
      renderPcc(pcc, newPccState, anyUpdatableOrders, onMountCallback)
    })
  })

  productCardComponents.forEach((pcc) => {
    const element = pcc.element
    element.addEventListener('click', (evt: Event) => {
      if (pcc.selectType == 'quantity') return
      const button = (evt.target as HTMLElement).closest('a')
      // NOTE: return if its, for example, a link from card description,
      // but not a bump add/remove button
      if (button && !button.closest('.elProductCardModernButton')) return

      if (pcc.selectType == 'single') {
        for (const otherPcc of productCardComponents) {
          if (otherPcc == pcc || !otherPcc.isChecked) continue
          updateCardByProductIdState(otherPcc.product.id, { quantity: 0 })
        }
        if (pcc.id.includes('Bump')) {
          if (pcc.isChecked) {
            updateCardByProductIdState(pcc.product.id, { quantity: 0 })
          } else {
            updateCardByProductIdState(pcc.product.id, { quantity: 1 })
          }
        } else {
          updateCardByProductIdState(pcc.product.id, { quantity: 1 })
        }
      } else if (pcc.selectType == 'multiple') {
        if (pcc.isChecked) {
          updateCardByProductIdState(pcc.product.id, { quantity: 0 })
        } else {
          updateCardByProductIdState(pcc.product.id, { quantity: 1 })
        }
      }
    })
  })
}

export const registerEventListeners = (pcc: ProductCardComponent, onMountCallback: OnMountCallback): void => {
  registerPriceEventListeners(pcc)
  onMountCallback && onMountCallback(pcc)
  if (pcc.selectType == 'quantity') {
    registerQuantityEventListeners(pcc)
  }
}

const registerVariantEventListeners = (pcc: ProductCardComponent): void => {
  const element = pcc.element
  const variantSelects = element.querySelectorAll<HTMLSelectElement>('.elVariantSelector')

  variantSelects.forEach((select, index) => {
    select.addEventListener('click', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
    })

    select.addEventListener('change', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()

      const newValues = [...variantSelects].map((e) => e.value)

      const selectedVariantId = getSelectedVariant(
        pcc.product.sorted_property_values,
        pcc.product.property_values_variant_mapping,
        pcc.variantValues,
        pcc.valuesPositions,
        index,
        newValues
      )

      const newVariant = pcc.product.variants.find((v) => v.id == String(selectedVariantId))
      const newPrice = newVariant.prices[0]

      updateCardByProductIdState(pcc.product.id, {
        variantId: newVariant.id,
        priceId: newPrice.id,
      })
    })
  })
}

const registerUpdatableOrders = (pcc: ProductCardComponent): void => {
  const element = pcc.element
  const previousOrders = element.querySelectorAll('.elProductCardOrder')
  const newOrder = element.querySelector('.elProductCardNewOrder')

  previousOrders?.forEach((orderElement) => {
    const showOrderDetailsLink = orderElement.querySelector('.elProductCardShowOrderDetailsLink')
    const upgradeAndDowngradeSelector = orderElement.querySelector('[name="upgradeDowngrade"]')
    const orderIndex = Number(orderElement.getAttribute('data-order-index'))
    const order = pcc.updatableOrders[orderIndex]

    orderElement.addEventListener('click', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
      const productId = pcc.product.id
      const renderType = order.type
      const cardDetails = pcc.updatableOrders[orderIndex].updatableItems[0].cardDetails
      const updateCardNewState: UpdatablePccState = {
        selectedOrderIndex: orderIndex,
        selectedUpdatableCartItemIndex: 0,
        renderType,
        variantId: cardDetails.variantId,
        priceId: cardDetails.priceId,

        cartItemRenderType: renderType,
        cartItemOrderId: cardDetails.orderId,
        cartItemLineItemId: cardDetails.lineItemId,
      }
      updateCardByProductIdState(productId, updateCardNewState)
    })

    showOrderDetailsLink?.addEventListener('click', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
      globalThis.Checkout.store.selectedOrderDetailId.set(order.id)
    })

    upgradeAndDowngradeSelector?.addEventListener('click', (evt) => {
      evt.preventDefault()
      evt.stopImmediatePropagation()
      evt.stopPropagation()
    })

    upgradeAndDowngradeSelector?.addEventListener('change', (evt: Event) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
      const updatableItemIndex = Number((evt.target as HTMLSelectElement).value)
      const cardDetails = order.updatableItems[updatableItemIndex].cardDetails
      const updateCardNewState: UpdatablePccState = {
        selectedUpdatableCartItemIndex: updatableItemIndex,
        variantId: cardDetails.variantId,
        priceId: cardDetails.priceId,

        cartItemOrderId: cardDetails.orderId,
        cartItemLineItemId: cardDetails.lineItemId,
      }
      updateCardByProductIdState(pcc.product.id, updateCardNewState)
    })
  })

  newOrder?.addEventListener('click', () => {
    const variant = pcc.product.variants[0]
    const variantId = variant.id
    const priceId = variant.prices[0].id
    const productId = pcc.product.id

    const updateCardNewState: UpdatablePccState = {
      variantId,
      priceId,
      selectedOrderIndex: -1,
      selectedUpdatableCartItemIndex: 0,

      cartItemRenderType: undefined,
      cartItemOrderId: undefined,
      cartItemLineItemId: undefined,
    }
    updateCardByProductIdState(productId, updateCardNewState)
  })
}

const registerPriceEventListeners = (pcc: ProductCardComponent): void => {
  const element = pcc.element
  const variantPriceSelector = element.querySelector('[name="variant_price"]')
  variantPriceSelector?.addEventListener('click', (evt) => {
    evt.preventDefault()
    evt.stopImmediatePropagation()
    evt.stopPropagation()
  })

  variantPriceSelector?.addEventListener('change', (evt) => {
    evt.stopImmediatePropagation()
    evt.stopPropagation()
    evt.preventDefault()
    const newPriceId = Number((evt.target as HTMLSelectElement).value) as PriceId
    updateCardByProductIdState(pcc.product.id, { priceId: newPriceId })
  })
}

const registerQuantityEventListeners = (pcc: ProductCardComponent): void => {
  const element = pcc.element
  const [minusButton, plusButton] = element.querySelectorAll('.elProductInputControls button')
  const input = element.querySelector<HTMLInputElement>('.elProductInputControls input')
  minusButton.addEventListener('click', (evt) => {
    evt.preventDefault()
    if (pcc.quantity === 0) return
    const newQuantity = pcc.quantity - 1
    updateCardByProductIdState(pcc.product.id, { quantity: newQuantity })
  })
  plusButton.addEventListener('click', (evt) => {
    evt.preventDefault()
    const newQuantity = pcc.quantity + 1
    updateCardByProductIdState(pcc.product.id, { quantity: newQuantity })
  })
  input.addEventListener('blur', (evt: Event) => {
    const newQuantity = parseInt((evt.target as HTMLInputElement).value)
    if (newQuantity == 0 || isNaN(newQuantity)) {
      updateCardByProductIdState(pcc.product.id, { quantity: 0 })
    } else {
      updateCardByProductIdState(pcc.product.id, { quantity: newQuantity })
    }
  })
}

export const renderAndMount = (pcc: ProductCardComponent, onMountCallback: OnMountCallback): void => {
  pcc.render()
  if (pcc.skipMountOnRender) return
  registerEventListeners(pcc, onMountCallback)
}

const getCartItemDataFromUpdatablePccState = (
  productId: ProductId,
  updatablePccState: UpdatablePccState
): CartItemDataIdentifier => {
  return {
    product_id: productId,
    variant_id: updatablePccState.variantId,
    price_id: updatablePccState.priceId,
    line_item_id: updatablePccState.cartItemLineItemId,
    type: updatablePccState.cartItemRenderType,
  }
}

const updateCart = (productId: ProductId, oldState: UpdatablePccState, newState: UpdatablePccState): void => {
  const oldItem = getCartItemDataFromUpdatablePccState(productId, oldState)
  const newItem = getCartItemDataFromUpdatablePccState(productId, newState)
  const operations = []
  if (oldState.quantity == newState.quantity && newState.quantity > 0 && !Cart.compareItems(oldItem, newItem, true)) {
    operations.push(Cart.createReplaceOperation(oldItem, { ...newItem, quantity: newState.quantity }))
  } else if (newState.quantity > 0 && oldState.quantity != newState.quantity) {
    operations.push(Cart.createAddOperation(newItem, newState.quantity))
  } else if (oldState.quantity > 0 && newState.quantity == 0) {
    operations.push(Cart.createRemoveOperation(oldItem))
  }

  operations.push(Cart.changeOrderId(newState.cartItemOrderId))

  Cart.dispatchEvents(operations)
}

const renderPcc = (
  pcc: ProductCardComponent,
  updates: UpdatablePccState,
  anyUpdatableOrders: boolean,
  onMountCallback: OnMountCallback
): void => {
  let newSelectType
  if (anyUpdatableOrders) {
    newSelectType = 'single'
  } else if (!pcc.product.bump) {
    newSelectType = globalThis.Checkout.productSelectType
  } else {
    newSelectType = pcc.selectType
  }

  if (
    pcc.renderType == updates.renderType &&
    pcc.variant.id == updates.variantId &&
    pcc.selected_price.id == updates.priceId &&
    pcc.quantity == updates.quantity &&
    pcc.selectType == newSelectType &&
    pcc.selectedOrderIndex == updates.selectedOrderIndex &&
    pcc.selectedUpdatableCartItemIndex == updates.selectedUpdatableCartItemIndex &&
    pcc.updatableOrders == updates.updatableOrders
  )
    return

  pcc.renderType = updates.renderType
  pcc.variant = globalThis.Checkout.variantsById[updates.variantId]
  pcc.selected_price = globalThis.Checkout.pricesById[updates.priceId]
  pcc.quantity = updates.quantity
  pcc.selectType = newSelectType
  pcc.selectedOrderIndex = updates.selectedOrderIndex
  pcc.selectedUpdatableCartItemIndex = updates.selectedUpdatableCartItemIndex
  pcc.updatableOrders = updates.updatableOrders

  pcc.isChecked = updates.quantity > 0
  if (pcc.isChecked) {
    pcc.element.classList.add('elProductSelected')
  } else {
    pcc.element.classList.remove('elProductSelected')
  }

  renderAndMount(pcc, onMountCallback)
}
