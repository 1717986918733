import { deepEqual, formatNumberToCurrency } from 'src/Elements/Utils/general'

import { renderedPath } from '../Utils/checkout-path'
import { isSavedAddress } from '../Utils/checkout-address'

import { Cart } from 'src/Elements/Cart'
import { CartData } from 'src/Elements/Cart/types'
import { SummaryLineItem, SummaryData, ServerAddressAttributes, ShippingOption } from '../types'

type CreateTransactionData = {
  currency: string
  amount: number
  label: string
  lineItems: { label: string; amount: string | number }[]
  requestShipping: boolean
}

type FetchStatus = {
  done?: boolean
  aborted?: boolean
}

type ServerReqSummaryAddresses = {
  billing_address?: ServerAddressAttributes
  delivery_address?: ServerAddressAttributes
}

type ServerReqSummaryLineItem = {
  line_item_id?: number
  variant_id: number
  price_id: number
  quantity: number
}

type ServerReqSummaryCart = {
  order_id: number
  line_items: ServerReqSummaryLineItem[]
}

type ServerReqSummary = {
  order: {
    full_preview: boolean
    should_backfill_billing_address: boolean
    coupon_codes: string | string[]
    selected_shipping_option?: ShippingOption
  } & ServerReqSummaryAddresses &
    ServerReqSummaryCart
}

class PreviewError extends Error {
  details
  constructor(message, details) {
    super(message)
    this.name = this.constructor.name
    this.details = details
    if (Error.captureStackTrace) Error.captureStackTrace(this, this.constructor)
  }
}

type ServerRespSummaryData = {
  currency: string
  currency_symbol: string
  line_items: SummaryLineItem[]
  billing_address: { city: string; region: string }
  shipping_quotes_response: any
  selected_shipping_option: any
  total_amount: {
    amount: string
  }
  total_amount_formatted: string
  discount_amount: {
    amount: string
  }
  discount_amount_formatted: string
  discounts: { name: string; code: string; amount: number }[]
  subtotal_amount: {
    amount: string
  }
  subtotal_amount_formatted: string
  shipping_amount: {
    amount: string
  }
  shipping_amount_formatted: string
  tax_amount: {
    amount: string
  }
  tax_amount_formatted: string
  upcoming_invoice?: {
    interim_amount: {
      amount: string
    }
    interim_amount_formatted: string
    line_items: {
      variant_name: string
      quantity: number
      amount: {
        amount: string
      }
      interim: boolean
      amount_formatted: string
    }[]
  }
}

export class CheckoutSummary {
  static #sendOrderPreviewTimer
  static #lastOrderPreviewData
  static #controllerOrderPreview
  static #orderPreviewSignal
  static #couponStateBeforeApplying
  static #pendingFetch: FetchStatus
  static #skipOrderSummaryUpdate

  static #createOrderPreviewSignal(): void {
    this.#controllerOrderPreview = new AbortController()
    this.#orderPreviewSignal = this.#controllerOrderPreview.signal
  }

  static #setLoadingShippingOptions(dataToPreview: ServerReqSummary): void {
    // NOTE: if the  only data changed was shippingOption, we dont want to shipping loading state
    const { selected_shipping_option: _tmpShippingOption1, ...dataToPreviewWithoutShippingOption } =
      dataToPreview?.order ?? {}
    const { selected_shipping_option: _tmpShippingOption2, ...lastOrderPreviewDataWithoutShippingOption } =
      this.#lastOrderPreviewData?.order ?? {}

    if (!deepEqual(dataToPreviewWithoutShippingOption, lastOrderPreviewDataWithoutShippingOption)) {
      globalThis.Checkout.store.loadingShipping.set(true)
    }
  }

  static skipOrderSummaryUpdateWithCb(cb: () => void): void {
    this.#skipOrderSummaryUpdate = true
    cb()
    this.#skipOrderSummaryUpdate = false
  }

  static sendOrderPreview(options?: { isCouponValidation?: boolean }): void {
    const isCouponValidation = !!options?.isCouponValidation
    if (this.#skipOrderSummaryUpdate) return
    const billingFields = globalThis.Checkout.store.billingFields.get()
    const shippingFields = globalThis.Checkout.store.shippingFields.get()
    if (!globalThis.Checkout.utils.hasValidDataForOrderPreview({ billingFields, shippingFields })) {
      if (globalThis.Checkout.store.summary.get().state != globalThis.Checkout.SummaryStates.WAITING) {
        globalThis.Checkout.store.summary.set({ state: globalThis.Checkout.SummaryStates.WAITING })
      }
      this.#lastOrderPreviewData = null
      return
    }

    const dataToPreview = this.#buildOrderPreviewPayload()
    if (!isCouponValidation && deepEqual(dataToPreview, this.#lastOrderPreviewData)) {
      return
    }

    this.#sendOrderPreviewTimer && clearTimeout(this.#sendOrderPreviewTimer)
    if (this.#controllerOrderPreview && this.#pendingFetch && !this.#pendingFetch.done && !this.#pendingFetch.aborted) {
      this.#controllerOrderPreview.abort()
    }

    if (globalThis.Checkout.store.summary.get().state != globalThis.Checkout.SummaryStates.CALCULATING) {
      if (globalThis.Checkout.store.payment.type.get() == 'apple-pay') {
        globalThis.Checkout.store.isUpdatingRebilly.set(true)
      }

      globalThis.Checkout.store.summary.set({ state: globalThis.Checkout.SummaryStates.CALCULATING })
    }

    const couponStateBeforeApplying = globalThis.Checkout.store.coupons.state.get()
    if (isCouponValidation && couponStateBeforeApplying != globalThis.Checkout.CouponStates.APPLYING) {
      this.#couponStateBeforeApplying = couponStateBeforeApplying
      globalThis.Checkout.store.coupons.state.set(globalThis.Checkout.CouponStates.APPLYING)
    }

    const thisFetch = (this.#pendingFetch = {})

    this.#setLoadingShippingOptions(dataToPreview)

    clearTimeout(this.#sendOrderPreviewTimer)
    this.#sendOrderPreviewTimer = setTimeout(() => {
      return this.fetchOrderPreview({ thisFetch })
    }, 500)
  }

  static fetchOrderPreview(options: { thisFetch?: FetchStatus; callRebillyUpdate?: boolean }): Promise<void> {
    options = options ?? {}
    const thisFetch = options.thisFetch ?? {}
    const callRebillyUpdate = options.callRebillyUpdate ?? true
    const dataToPreview = this.#buildOrderPreviewPayload()
    this.#lastOrderPreviewData = dataToPreview
    let summaryData: ServerRespSummaryData
    let orderPreviewError
    this.#createOrderPreviewSignal()
    return fetch(window.location.origin + renderedPath() + '/order_preview', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(dataToPreview),
      signal: this.#orderPreviewSignal,
    })
      .then((response) => response?.json())
      .then((data) => {
        if (!data) return
        if (data.error) throw new PreviewError(data.error, data.details)
        summaryData = data as ServerRespSummaryData
      })
      .catch((error) => {
        if (error.name == 'AbortError') {
          thisFetch.aborted = true
        } else {
          console.error(error)
          orderPreviewError = error
        }
      })
      .finally(() => {
        if (thisFetch.aborted) {
          return
        }

        if (summaryData) {
          const { shipping_quotes_response, selected_shipping_option } = summaryData

          // NOTE: avoids infinite loop
          this.skipOrderSummaryUpdateWithCb(() => {
            globalThis.Checkout.store.shippingOptions.set(shipping_quotes_response?.options ?? [])
            globalThis.Checkout.store.shippingOption.set(selected_shipping_option)
            globalThis.Checkout.store.loadingShipping.set(false)
            this.#backfillBillingAddress(summaryData)
          })

          const currentCode = globalThis.Checkout.store.coupons.currentCode.get()
          const couponCodeApplied = summaryData.discounts?.find(
            (discount) => discount.code?.toUpperCase() == currentCode?.toUpperCase()
          )
          if (couponCodeApplied) {
            globalThis.Checkout.store.coupons.appliedCode.set(currentCode)
            globalThis.Checkout.store.coupons.state.set(globalThis.Checkout.CouponStates.APPLIED)
          } else {
            globalThis.Checkout.store.coupons.state.set(globalThis.Checkout.CouponStates.READY)
          }

          const newSummaryStoreData = {
            state: globalThis.Checkout.SummaryStates.OK,
            data: this.#processOrderPreviewResponseForSummary(summaryData),
          }
          globalThis.Checkout.store.summary.set(newSummaryStoreData)
          this.updateRebillyTransactionData(callRebillyUpdate)
        } else if (orderPreviewError) {
          globalThis.Checkout.store.loadingShipping.set(false)
          const error = orderPreviewError.details?.[0]
          if (error?.type == 'InvalidPostalCodeError') {
            globalThis.Checkout.store.summary.set({
              state: globalThis.Checkout.SummaryStates.WAITING,
            })
            globalThis.Checkout.store.showAllErrors.billing.set(true)
            globalThis.Checkout.store.billingApiErrorsByField.set({ zip: { message: error.message } })
            this.updateRebillyTransactionData(false)
          } else if (error?.type == 'InvalidCouponError') {
            globalThis.Checkout.store.coupons.appliedCode.set('')
            globalThis.Checkout.store.coupons.errorMessage.set(error.message)
            globalThis.Checkout.store.coupons.state.set(globalThis.Checkout.CouponStates.ERROR)
            globalThis.Checkout.store.summary.set({
              state: globalThis.Checkout.SummaryStates.OK,
              data: this.#processOrderPreviewResponseForSummary(error.preview),
            })
            this.updateRebillyTransactionData(callRebillyUpdate)
          } else {
            globalThis.Checkout.store.coupons.state.set(this.#couponStateBeforeApplying)
            const newSummaryStoreData = { state: globalThis.Checkout.SummaryStates.ERROR }
            globalThis.Checkout.store.summary.set(newSummaryStoreData)
            this.updateRebillyTransactionData(false)
          }
        }
        thisFetch.done = true
      })
  }

  static #backfillBillingAddress(summaryData: ServerRespSummaryData): void {
    const checkoutBilling = globalThis.Checkout.store.billing.get()
    if (summaryData.billing_address) {
      const { city: summaryCity, region: summaryRegion } = summaryData.billing_address
      if (!checkoutBilling.city || !checkoutBilling.state) {
        globalThis.Checkout.store.billing.set({
          ...checkoutBilling,
          ...(!checkoutBilling.city ? { city: summaryCity } : {}),
          ...(!checkoutBilling.state ? { state: summaryRegion } : {}),
        })
      }
    }
  }

  static #processOrderPreviewResponseForSummary(summaryData: ServerRespSummaryData): SummaryData {
    return {
      currency: summaryData.currency,
      line_items: summaryData.line_items,
      total: {
        amount: parseFloat(summaryData.total_amount.amount),
        formatted: summaryData.total_amount_formatted,
      },
      discount: {
        amount: parseFloat(summaryData.discount_amount.amount),
        formatted: summaryData.discount_amount_formatted,
      },
      discounts: (summaryData.discounts ?? []).map((discount) => {
        return {
          name: discount.name,
          code: discount.code,
          amount_formatted: '-' + formatNumberToCurrency(summaryData.currency_symbol, discount.amount),
        }
      }),
      subtotal: {
        amount: parseFloat(summaryData.subtotal_amount.amount),
        formatted: summaryData.subtotal_amount_formatted,
      },
      shipping: {
        amount: parseFloat(summaryData.shipping_amount.amount),
        formatted: summaryData.shipping_amount_formatted,
      },
      tax: {
        amount: parseFloat(summaryData.tax_amount.amount),
        formatted: summaryData.tax_amount_formatted,
      },
      ...(summaryData.upcoming_invoice?.interim_amount
        ? {
            interim: {
              amount: parseFloat(summaryData.upcoming_invoice.interim_amount.amount),
              formatted: summaryData.upcoming_invoice.interim_amount_formatted,
            },
          }
        : {}),
      ...(summaryData.upcoming_invoice
        ? {
            upcoming_invoice: {
              line_items: summaryData.upcoming_invoice.line_items
                .filter((i) => i.interim)
                .map((item) => {
                  return {
                    variant_name: item.variant_name,
                    quantity: item.quantity,
                    amount: parseFloat(item.amount.amount),
                    formatted: item.amount_formatted,
                  }
                }),
            },
          }
        : {}),
    }
  }

  static #buildCartDetails(): ServerReqSummaryCart {
    const cart = Cart.stores.cartData.get() as CartData
    // TODO: I may not to user order_id for reactivations as in this case we will
    // wanna create a new order for now
    const orderId = cart.order_id
    const lineItems = cart.items.map(({ price_id, line_item_id, variant_id, quantity }) => {
      const data: ServerReqSummaryLineItem = {
        variant_id: variant_id,
        price_id: price_id,
        ...(line_item_id ? { line_item_id: line_item_id } : {}),
        quantity,
      }
      return data
    })
    return { order_id: orderId, line_items: lineItems }
  }

  static #buildOrderPreviewPayload(): ServerReqSummary {
    // NOTE: currentCode is manily applied in case of code change and clicking Apply button
    // appliedCode is used in all subsequent requests - eg. when product is changed after coupon is applied
    const couponState = globalThis.Checkout.store.coupons.state.get()
    let couponCode
    if (couponState == globalThis.Checkout.CouponStates.APPLYING) {
      couponCode = globalThis.Checkout.store.coupons.currentCode.get()
    } else {
      couponCode = globalThis.Checkout.store.coupons.appliedCode.get()
    }
    const selected_shipping_option = globalThis.Checkout.store.shippingOption.get()
    return {
      order: {
        full_preview: true,
        should_backfill_billing_address: true,
        coupon_codes: globalThis.Checkout.store.featureFlags.isCouponEnabled.get() ? couponCode : [],
        ...(selected_shipping_option ? { selected_shipping_option } : {}),
        ...(this.#buildAddressParams() ?? {}),
        ...this.#buildCartDetails(),
      },
    }
  }

  static #buildAddressParams(): ServerReqSummaryAddresses {
    const billing = globalThis.Checkout.store.billing.get()
    const shipping = globalThis.Checkout.store.shipping.get()
    const mode = globalThis.Checkout.store.checkout.mode.get()

    if (mode == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE) {
      return
    }

    let billingData, shippingData
    if (isSavedAddress(billing)) {
      billingData = {
        id: billing.id,
      }
    } else {
      billingData = {
        address_one: billing.address,
        address_two: billing.address_2,
        city: billing.city,
        state: billing.state,
        country: billing.country,
        postal_code: billing.zip,
      }
    }

    if (isSavedAddress(shipping)) {
      shippingData = {
        id: shipping.id,
        address_one: shipping.address,
        address_two: shipping.address_2,
        city: shipping.city,
        state: shipping.state,
        country: shipping.country,
        postal_code: shipping.zip,
      }
    } else {
      shippingData = {
        address_one: shipping.address,
        address_two: shipping.address_2,
        city: shipping.city,
        state: shipping.state,
        country: shipping.country,
        postal_code: shipping.zip,
      }
    }

    if (globalThis.Checkout.utils.hasPhysicalProducts()) {
      const billingSameAsShippingData = globalThis.Checkout.store.billingSameAsShipping.get()
      if (globalThis.Checkout.utils.skipBillingAddress(globalThis.Checkout.store)) {
        return { delivery_address: shippingData }
      }
      if (mode == globalThis.Checkout.CheckoutStates.OTO) {
        return {
          delivery_address: shippingData,
          billing_address: billingData,
        }
      }
      if (billingSameAsShippingData) {
        return {
          delivery_address: shippingData,
          billing_address: shippingData,
        }
      } else {
        return {
          delivery_address: shippingData,
          billing_address: billingData,
        }
      }
    } else {
      if (globalThis.Checkout.utils.skipBillingAddress(globalThis.Checkout.store)) return
      return { billing_address: billingData }
    }
  }

  static updateRebillyTransactionData(shouldCallRebilly: boolean): void {
    const mode = globalThis.Checkout.store.checkout.mode.get()
    const summary = globalThis.Checkout.store.summary.get()
    const cart = Cart.stores.cartData.get()
    const shippingEnabled = globalThis.Checkout.utils.hasPhysicalProducts()

    const summaryData = summary.data
    const lineItems = summaryData?.line_items ?? []
    const RebillyFullyInitialized =
      globalThis.Checkout.store.payment.state.get() == globalThis.Checkout.PaymentStates.INITIALIZED
    if (
      !shouldCallRebilly ||
      mode == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE ||
      !lineItems.length ||
      !RebillyFullyInitialized
    ) {
      globalThis.Checkout.store.isUpdatingRebilly.set(false)
      return
    }

    const transactionData = this.buildTransactionData(summaryData, cart, shippingEnabled)
    globalThis.Rebilly.update({ transactionData }).then(() => {
      globalThis.Checkout.store.isUpdatingRebilly.set(false)
    })
  }

  static buildTransactionData(
    summaryData: SummaryData,
    cart: CartData,
    shippingEnabled: boolean
  ): CreateTransactionData {
    const { currency, line_items } = summaryData
    const lineItems = line_items.map(({ price, description }, index) => {
      let label
      if (line_items.length == cart.items.length) {
        const variantId = cart.items[index].variant_id
        const variant = globalThis.Checkout.variantsById[variantId]
        label = variant.name
      } else {
        label = description
      }
      return {
        label,
        amount: price,
      }
    })

    if (summaryData.tax?.amount > 0) {
      lineItems.push({
        label: 'Taxes',
        amount: summaryData.tax.amount,
      })
    }

    if (summaryData.shipping?.amount > 0) {
      lineItems.push({
        label: 'Shipping',
        amount: summaryData.shipping.amount,
      })
    }

    return {
      currency,
      amount: summaryData.total.amount,
      label: 'Total Purchase',
      lineItems,
      requestShipping: shippingEnabled,
    }
  }
}
