
 import  { atom } from "nanostores"

import "./modal-v1"
import "./checkout-saved-address-form-v1"
import "./radio-v1"
import "./checkout-shipping-address-v1"
import { addressToString } from '@yggdrasil/Checkout/V2/Utils/checkout-address'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutSavedContactDetailsV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  mount() {
    this.modalShow = atom(false)

    this.getOpenModalButton().addEventListener('click', (evt) => {
      evt.preventDefault();
      this.modalShow.set(true)
    })
    this.getCloseButton().addEventListener('click', (evt) => {
      evt.preventDefault();
      this.modalShow.set(false)
    })
    this.getModal().onClose = () => {
      this.modalShow.set(false)
    }

    this.getButtonElement().addEventListener('click', (evt) => {
      evt.preventDefault();
      this.updateAddress();
    })

    this.modalShow.listen((value) => {
      if (value) {
        this.showModal()
      } else {
        this.hideModal()
        const currentAddress = Checkout.store.shipping.get()
        const id = currentAddress?.id ?? 'new-address'
        this.getSavedAddressForm().getAddressInputByValue(id).checked = true
      }
    })
    Checkout.store.shipping.listen((shipping) => {
      const shippingAddressLength = this.getSavedContainerWrapper().getAttribute('data-contact-shipping-addresses-length')
      if (!shipping.id == null && (!shippingAddressLength || shippingAddressLength == '0')) {
        this.getSavedContainerWrapper().setAttribute('data-contact-shipping-addresses-length', "1")
      }
      this.refreshAddress(shipping)
    })
    Checkout.store.contact_pending_auth.listen((contact_pending_auth) => {
      const authenticated = contact_pending_auth.authenticated
      this.setShowContactDetails(!authenticated)
    })
    Checkout.store.contact.listen((contact) => {
      const { email, first_name, last_name, phone_number } = contact
      this.element.querySelector('.contact_email').innerHTML = email || ''
      this.element.querySelector('.contact_first_name').innerHTML = first_name || ''
      this.element.querySelector('.contact_last_name').innerHTML = last_name || ''    
      this.element.querySelector('.elSavedPhone').innerHTML = phone_number ? `
        <i class="fas fa-phone"></i>
        <span class="contact_phone_number"> ${contact.phone_number} </span>      
      ` : ``                    
    })

    Checkout.store.checkout.mode.subscribe((mode) => {
      this.getSavedContainerWrapper().dataset.mode = mode
    })

    Checkout.computed.hasPhysicalProducts.subscribe(hasPhysicalProducts => {
      const defaultLabel = 'Shipping Information'
      const formDetailsLabel = this.element.querySelector('.elCheckoutFormLabel')
      if (!formDetailsLabel) return

      this.getSavedContainerWrapper().dataset['selectedProductType'] = hasPhysicalProducts ? 'physical' : 'digital'
      
      if (hasPhysicalProducts) {
        formDetailsLabel.innerHTML = this.shippingHeadline || 'Shipping Information'
      } else {
        formDetailsLabel.innerHTML = this.shippingHeadline == 'Shipping Information' && this.showContactDetails() ? 'Contact Information' : this.shippingHeadline
      }
    })

    Checkout.computed.isNewDigitalWalletPayment.subscribe(isNewDigitalWalletPayment => {
      if (isNewDigitalWalletPayment) {
        this.hide()
      } else {
        this.show()
      }
    })
  }
  hide () {
    this.element.classList.add('elHide')
  }
  show () {
    this.element.classList.remove('elHide')
  }
  updateAddress() {
    const savedAddrComp = this.getSavedAddressForm()
    if (savedAddrComp.isNewAddress()) {
      if (savedAddrComp.validateNewAddressForm()) {
        const newAddress = savedAddrComp.getAddressFormValues()
        Checkout.store.shipping.set(newAddress)
        this.modalShow.set(false)
      }
    } else {
      const selectedAddressId = savedAddrComp.getSelectedAddressId()
      const newAddress = savedAddrComp.getSavedAddressById(selectedAddressId)
      if (newAddress) {
        Checkout.store.shipping.set(newAddress)
      }
      this.modalShow.set(false)
    }
  }
  refreshAddress(address) {
    const detailsTextElement = this.element.querySelector('.elSavedDetailsText')
    const detailsIconElement = this.element.querySelector('.elSavedDetailsIcon')
    if (address && address.address) {
      const formattedText = addressToString(address)
      detailsTextElement.innerHTML = formattedText
      detailsTextElement.classList.remove('elError')
      detailsIconElement.classList.remove('elError')
    }
  }
  getModal() {
    return this.getComponent("Modal/V1");
  }
  getOpenModalButton() {
    return this.element.querySelector('.elSavedDetailsEdit')
  }
  showModal() {
    this.getModal().element.style.display = 'flex'
  }
  hideModal() {
    this.getModal().element.style.display = 'none'
  }
  getSavedAddressForm() {
    return this.getComponent('CheckoutSavedAddressForm/V1')
  }
  getButtonElement() {
    return this.getModal().element.querySelector('.elButton')
  }
  getCloseButton() {
    return this.getModal().element.querySelector('.elCheckoutModalFormClose')
  }
  getSavedContainerWrapper() {
    return this.element.querySelector('.elCheckoutSavedContactDetailsWrapper')
  }
  showContactDetails() {
    return this.getSavedContainerWrapper().getAttribute('data-show-contact-details')
  }
  setShowContactDetails(value) {
    this.getSavedContainerWrapper().setAttribute('data-show-contact-details', value)
  }



}

window["CheckoutSavedContactDetailsV1"] = CheckoutSavedContactDetailsV1

