import { validateInput } from 'src/Elements/Utils/inputValidator'
import { renderedHref } from '../Utils/checkout-path'
import { isSavedAddress } from '../Utils/checkout-address'
import { PaymentMethodType, Contact, SummaryData, ShippingOption, ServerAddressAttributes } from '../types'
import { sleepMs } from 'src/Elements/Utils/general'
import { Cart } from 'src/Elements/Cart'
import { CartData, VariantId, LineItemId, PriceId } from 'src/Elements/Cart/types'

export type UpdateTransactionData = {
  amount: number
  lineItems: { label: string; amount: string | number }[]
  status?: 'success' | 'fail'
  shippingOptions?: { id: string; label: string; description: string; amount: number }[]
}

type SubmitPurchaseCartDetailsProductVariants = {
  id: VariantId
  line_item_id?: LineItemId
  price_id: PriceId
  quantity: number
}
type SubmitPurchaseCartDetails = {
  order_id: number
  product_variants: SubmitPurchaseCartDetailsProductVariants[]
}

type SubmitPayloadBillingAddress = {
  billing_address_attributes?: ServerAddressAttributes
  billing_same_as_shipping?: boolean
}
type SubmitPayloadShippingAddress = {
  shipping_addresses_attributes: {
    physical_address_attributes: ServerAddressAttributes
  }[]
}

type PaymentMethodDetails = {
  payment_method_id?: number
  payment_method_type?: PaymentMethodType
  rebilly_token?: string
}

type SubmitPayloadPurchase = {
  process_new_order: boolean
  selected_shipping_option?: any
  coupon_codes?: string[]
} & SubmitPurchaseCartDetails &
  PaymentMethodDetails

type SubmitPayloadContact = Contact & SubmitPayloadShippingAddress

export type SubmitPayload = {
  purchase: SubmitPayloadPurchase
  contact: SubmitPayloadContact
  straightforward_onboarding_flow_redirect_url?: string
  redirect_to?: string
}
const pageElementContexts = [
  { selector: '[data-page-element="ModalContainer/V1"]', context: 'Modal' },
  { selector: '[data-page-element="ContentNode"]', context: 'ContentNode' },
]
export class CheckoutSubmit {
  static #redirecTo = document.querySelector('[href="#submit-checkout-form"]').getAttribute('data-on-submit-go-to')
  static #threedsListenerEnabled = false
  static #formSubmitPayload = null

  static submitFromButtonClick(submitButton: HTMLElement): Promise<void> | void {
    this.#add3dsListener()
    const checkoutElement = submitButton.closest<HTMLElement>('.elCheckout')
    if (
      this.checkSubmitShowErrors({ skipFields: new Set(['payment']) }) &&
      this.checkSubmitShowErrors({ onlyFields: new Set(['payment']) }) &&
      this.checkSubmit(checkoutElement)
    ) {
      globalThis.Checkout.store.state.set(globalThis.Checkout.StoreStates.SUBMITTING)
      globalThis.Checkout.store.submitting.set({
        state: globalThis.Checkout.SubmittingStates.START,
      })
      const isUpgradeDowngrade =
        globalThis.Checkout.store.checkout.mode.get() == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE
      const capturedPaymentMethodId = globalThis.Checkout.store.payment.id.get()
      const canSkipSubmitRebilly = isUpgradeDowngrade || capturedPaymentMethodId
      if (canSkipSubmitRebilly) {
        const payload = this.buildPayloadFromStore()
        return this.submit(payload)
      } else {
        const paymentType = globalThis.Checkout.store.payment.type.get()
        if (paymentType == 'payment-card') {
          // NOTE: payment-card always obtain a token calling Rebilly.createToken method
          return this.#submitRebilly().then((tokenData) => {
            globalThis.Checkout.store.payment[paymentType].token.set(tokenData.id)
            const payload = this.buildPayloadFromStore()
            return this.submit(payload)
          })
        } else {
          const payload = this.buildPayloadFromStore()
          return this.submit(payload)
        }
      }
    }
  }

  static checkSubmitShowErrors(options: { onlyFields?: Set<string>; skipFields?: Set<string> }): boolean {
    const { onlyFields, skipFields } = options ?? {}
    return Object.entries(globalThis.Checkout.computed.errorsByName)
      .map(([name, computed]: [string, any]) => {
        if (onlyFields && !onlyFields.has(name)) return true
        if (skipFields && skipFields.has(name)) return true

        globalThis.Checkout.store.showAllErrors[name].set(true)
        return !globalThis.Checkout.utils.hasErrors(computed.get())
      })
      .every((v) => !!v)
  }

  static checkSubmit(checkoutElement: HTMLElement): boolean {
    if (globalThis.Checkout.utils.canSubmit() && this.checkValidCustomFields(checkoutElement)) {
      return true
    } else {
      globalThis.Checkout.store.incrScrollToFirstVisibleError.set(
        globalThis.Checkout.store.incrScrollToFirstVisibleError.get() + 1
      )
      return false
    }
  }

  static getCheckoutContext(checkoutElement: HTMLElement): HTMLElement {
    let found
    pageElementContexts.find(({ selector }) => {
      found = checkoutElement.closest(selector)
      return found
    })
    return found
  }

  static checkValidCustomFields(checkoutElement: HTMLElement): boolean {
    const contextElement = this.getCheckoutContext(checkoutElement)
    if (!contextElement) return true

    contextElement
      .querySelectorAll<HTMLElement>('.elFormItemWrapper:not(.elCheckout .elFormItemWrapper)')
      .forEach((el) => {
        el.classList.remove('elInputError', 'elInputWarning', 'elInputValid')
      })

    const formItems = Array.from(
      contextElement.querySelectorAll<HTMLElement>('.elFormItem.required1:not(.elCheckout .elFormItem.required1)')
    ).filter((input) => !!input.getAttribute('data-custom-type') || input.getAttribute('type') == 'checkbox')

    const results = []
    formItems.forEach((input) => {
      const result = validateInput(input)
      results.push(result)

      let thisInput = $(input)
      const parent = thisInput.parents('.elFormItemWrapper')
      thisInput =
        parent.length && parent.find('.inputHolder, .borderHolder, .elCheckbox').length
          ? parent.find('.inputHolder, .borderHolder, .elCheckbox')
          : thisInput

      if (result) {
        thisInput.css('border-color', '#4a8920')
        thisInput.css('border-width', '3px')
      } else {
        thisInput.css('border-color', '#B91517')
        thisInput.css('border-width', '3px')
      }
    })

    return results.every((r) => !!r)
  }

  static buildPayloadFromStore(overrides?: Partial<{ contact: Contact }>): SubmitPayload {
    overrides = overrides ?? {}
    const addressParams = this.#buildAddressParams() ?? {}
    const paymentDetails = this.#buildPaymentMethodDetails() ?? {}
    const purchaseCartDetails = this.#buildPurchaseCartDetails()
    // NOTE: Temp fix for FHL 2023
    const customFields = this.#buildCustomFields()
    const contact = overrides.contact ?? globalThis.Checkout.store.contact.get()
    const couponCode =
      globalThis.Checkout.store.featureFlags.isCouponEnabled.get() &&
      globalThis.Checkout.store.coupons.appliedCode.get()
    const couponData = couponCode ? { coupon_codes: [couponCode] } : {}

    const payload: SubmitPayload = {
      // NOTE: Temp fix for FHL 2023
      ...(customFields ? customFields : {}),
      contact: {
        ...contact,
        ...(addressParams.shipping ?? {}),
      },
      ...(addressParams.billing ?? {}),
      purchase: {
        ...couponData,
        ...purchaseCartDetails,
        ...paymentDetails,
        process_new_order: true,
      },
    }
    if (this.#shouldIncludeRegistration()) payload['registration'] = this.#buildRegistrationDetails()
    const selected_shipping_option = globalThis.Checkout.store.shippingOption.get()
    if (selected_shipping_option) {
      payload.purchase.selected_shipping_option = selected_shipping_option
    }
    if (this.#redirecTo) {
      payload.redirect_to = this.#redirecTo
    }

    if (globalThis.straightforward_onboarding_flow_redirect_url) {
      payload.straightforward_onboarding_flow_redirect_url = globalThis.straightforward_onboarding_flow_redirect_url
    }
    return payload
  }

  static #leadSourceGenerator(): Record<string, string> {
    const DEFAULT_MAX_CHARS_LENGTH = 512
    const leadQueryParamMapping = {
      utm_source: {
        name: 'source',
      },
      utm_medium: {
        name: 'medium',
      },
      utm_campaign: {
        name: 'campaign',
      },
      utm_term: {
        name: 'term',
      },
      utm_content: {
        name: 'content',
      },
      affiliate: {},
      subAffiliate: {},
      clickId: {},
      salesAgent: {},
    }
    const params = new URLSearchParams(globalThis.location.search)
    return Array.from(params.keys()).reduce((acc, key) => {
      const mappedValue = leadQueryParamMapping[key]
      if (mappedValue) {
        const paramValue = params.get(key)
        const leadSourceName = mappedValue.name ?? key
        acc[leadSourceName] = paramValue.substring(0, DEFAULT_MAX_CHARS_LENGTH)
      }
      return acc
    }, {})
  }

  static #submitRebilly(): Promise<{ id: string }> {
    const selectedPaymentMethod = globalThis.Checkout.store.payment.type.get()
    const Rebilly = globalThis.Rebilly
    let extraData = {
      method: selectedPaymentMethod,
    } as any

    const leadSource = this.#leadSourceGenerator()
    if (Object.keys(leadSource).length) {
      extraData = { ...extraData, leadSource }
    }

    const form = document.querySelector('#cfAR')

    const rebillyDataMapping = {
      firstName: 'first_name',
      lastName: 'last_name',
      emails: 'email',
    }

    const rebillyFieldKeyMapping = {
      'paymentInstrument.cvv': 'cvv',
    }

    Object.entries(rebillyDataMapping).forEach(([rebillyKey, dataKey]) => {
      const input = form.querySelector<HTMLInputElement>(`[data-rebilly="${rebillyKey}"]`)
      if (input) {
        input.value = globalThis.Checkout.store.contact.get()[dataKey]
      }
    })
    return Rebilly.createToken(form, extraData).catch((e) => {
      console.error(e)
      if (e.invalidFields && e.invalidFields.length) {
        e.invalidFields.forEach((error) => {
          const key = rebillyFieldKeyMapping[error.field]
          if (key) {
            const event = { valid: false, error: { message: error.message } }
            globalThis.Checkout.store.payment['payment-card'].events.setKey(key, event)
          }
        })
        globalThis.Checkout.store.submitting.set({
          state: globalThis.Checkout.SubmittingStates.ERROR,
          code: globalThis.Checkout.ErrorTypes.REBILLY_ERROR,
        })
      } else {
        let message = e.message
        if (e.details.length) {
          const details = e.details.join(' - ')
          message = `${message} - ${details}`
        }
        globalThis.Checkout.store.submitting.set({
          state: globalThis.Checkout.SubmittingStates.ERROR,
          code: globalThis.Checkout.ErrorTypes.REBILLY_ERROR,
          message: message,
        })
      }
      globalThis.Checkout.store.incrScrollToFirstVisibleError.set(
        globalThis.Checkout.store.incrScrollToFirstVisibleError.get() + 1
      )
    })
  }

  static generateShippingOptionId(shippingOption: ShippingOption): string {
    return [shippingOption.description, shippingOption.amount_formatted].join('$')
  }

  static buildDigitalWalletTransactionData(
    summaryData: SummaryData,
    cart: CartData,
    shippingEnabled: boolean,
    shippingOptions: ShippingOption[]
  ): UpdateTransactionData {
    const { line_items } = summaryData
    const lineItems = line_items.map(({ price, description }, index) => {
      let label
      if (line_items.length == cart.items.length) {
        const variantId = cart.items[index].variant_id
        const variant = globalThis.Checkout.variantsById[variantId]
        label = variant.name
      } else {
        label = description
      }
      return {
        label,
        amount: price,
      }
    })

    if (summaryData.tax?.amount > 0) {
      lineItems.push({
        label: 'Taxes',
        amount: summaryData.tax.amount,
      })
    }

    if (summaryData.shipping?.amount > 0) {
      lineItems.push({
        label: 'Shipping',
        amount: summaryData.shipping.amount,
      })
    }

    return {
      amount: summaryData.total.amount,
      lineItems,
      ...(shippingEnabled
        ? {
            status: shippingOptions.length ? 'success' : 'fail',
            shippingOptions: shippingOptions.map((shippingOption) => ({
              id: this.generateShippingOptionId(shippingOption),
              label: shippingOption.description,
              description: '',
              amount: Number(shippingOption.amount.amount),
            })),
          }
        : {}),
    }
  }

  static #buildPurchaseCartDetails(): SubmitPurchaseCartDetails {
    const cartData = Cart.stores.cartData.get()
    const lineItems: SubmitPurchaseCartDetailsProductVariants[] = cartData.items.map(
      ({ variant_id, price_id, line_item_id, quantity }): SubmitPurchaseCartDetailsProductVariants => {
        return {
          id: variant_id,
          quantity,
          price_id,
          ...(line_item_id ? { line_item_id } : {}),
        }
      }
    )
    return { order_id: cartData.order_id, product_variants: lineItems }
  }

  static #buildPaymentMethodDetails(): PaymentMethodDetails {
    const mode = globalThis.Checkout.store.checkout.mode.get()
    if (mode == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE) return

    const paymentType = globalThis.Checkout.store.payment.type.get()
    const rebilly_token = globalThis.Checkout.store.payment[paymentType].token.get()
    const payment_method_id = globalThis.Checkout.store.payment.id.get()
    const payment_method_type = globalThis.Checkout.store.payment.type.get()

    if (payment_method_id) return { payment_method_id, rebilly_token: null }

    return {
      payment_method_id: null,
      payment_method_type,
      rebilly_token,
    }
  }

  static #buildCustomFields(): Record<string, string> {
    const fields = {}
    document
      .querySelectorAll<HTMLInputElement>(
        "[type='custom_type'], select[data-custom-type], [type='custom_type'] [type='checkbox']"
      )
      .forEach((input) => {
        fields[input.name] = input.type === 'checkbox' ? input.checked : input.value
      })
    return fields
  }

  static #buildRegistrationDetails(): { calendar_event_id: string; occurs_at: string } {
    return {
      calendar_event_id: document.getElementsByName('registration[calendar_event_id]')[0].value,
      occurs_at: document.getElementsByName('registration[occurs_at]')[0].value,
    }
  }

  static #shouldIncludeRegistration(): boolean {
    return document.getElementsByName('registration[calendar_event_id]')?.length > 0
  }

  static submit(payload: SubmitPayload): void {
    if (localStorage.getItem('cf2:devtools:enabled')) {
      console.log('submitted data', payload)
    }

    globalThis.CFDispatchEvent(globalThis.CFEvents.FORM_SUBMITTED, payload)
    let timer = null
    this.#submitOrderAsync(
      payload,
      3,
      () => {
        clearInterval(timer)
        globalThis.Checkout.store.submitting.set({
          state: globalThis.Checkout.SubmittingStates.START,
        })
      },
      (sleepTime) => {
        let remainingSeconds = sleepTime / 1000
        clearInterval(timer)
        timer = setInterval(() => {
          remainingSeconds -= 1
          globalThis.Checkout.store.submitting.set({
            state: globalThis.Checkout.SubmittingStates.WAITING_ON_QUEUE,
            remainingSeconds,
          })
        }, 1000)
      }
    )
      .then((response) => {
        if (response.ok) {
          this.#formSubmitPayload = payload
          const rawFlashes = response.headers.get('X-CF2-FLASHES')
          const flashes = JSON.parse(rawFlashes ?? '{}')
          if (flashes.error) {
            globalThis.Checkout.store.submitting.set({
              state: globalThis.Checkout.SubmittingStates.ERROR,
              code: globalThis.Checkout.ErrorTypes.SERVER_ERROR_WITH_MESSAGE,
              message: flashes.error,
            })
          } else {
            globalThis.CFDispatchEvent(globalThis.CFEvents.FORM_SUBMITTED_OK, payload)
            if (response.headers.get('X-CF2-APPROVAL-URL')) {
              globalThis.Checkout.store.threeds.set({
                show: true,
                approvalUrl: response.headers.get('X-CF2-APPROVAL-URL'),
              })
            } else {
              globalThis.Checkout.store.submitting.set({
                state: globalThis.Checkout.SubmittingStates.DONE,
              })
              globalThis.Checkout.store.state.set(globalThis.Checkout.StoreStates.SUBMITTED)
              globalThis.CFDispatchEvent(globalThis.CFEvents.FORM_SUBMITTED_FINALIZED, payload)
              this.#handleFormSubmitRedirect(response, response.headers.get('Location'))
            }
          }
        } else if (response.status >= 300 && response.status < 400) {
          // NOTE: This code is never executed...
          globalThis.Checkout.store.submitting.set({
            state: globalThis.Checkout.SubmittingStates.DONE,
          })
          globalThis.Checkout.store.state.set(globalThis.Checkout.StoreStates.SUBMITTED)
          globalThis.CFDispatchEvent(globalThis.CFEvents.FORM_SUBMITTED_FINALIZED, payload)
          this.#handleFormSubmitRedirect(response, response.headers.get('Location') ?? globalThis.location.href)
        } else if (response.status === 429) {
          clearInterval(timer)
          globalThis.Checkout.store.submitting.set({
            state: globalThis.Checkout.SubmittingStates.ERROR,
            code: globalThis.Checkout.ErrorTypes.EXCEEDED_MAX_RETRIES,
          })
        } else {
          globalThis.Checkout.store.submitting.set({
            state: globalThis.Checkout.SubmittingStates.ERROR,
            code: globalThis.Checkout.ErrorTypes.UNHANDLED_SERVER_RESPONSE,
          })
        }
      })
      .catch((err) => {
        console.error(err)
        clearInterval(timer)
        globalThis.Checkout.store.submitting.set({
          state: globalThis.Checkout.SubmittingStates.ERROR,
          code: globalThis.Checkout.ErrorTypes.SERVER_ERROR,
        })
      })
  }

  static #buildAddressParams(): {
    billing?: SubmitPayloadBillingAddress
    shipping?: SubmitPayloadShippingAddress
  } {
    const mode = globalThis.Checkout.store.checkout.mode.get()
    const billing = globalThis.Checkout.store.billing.get()
    const shipping = globalThis.Checkout.store.shipping.get()

    if (mode == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE) {
      return
    }

    let billing_address_attributes: ServerAddressAttributes, physical_address_attributes: ServerAddressAttributes
    if (isSavedAddress(billing)) {
      billing_address_attributes = {
        id: billing.id,
      }
    } else {
      billing_address_attributes = {
        address_one: billing.address,
        address_two: billing.address_2,
        city: billing.city,
        region_name: billing.state,
        country_id: billing.country,
        postal_code: billing.zip,
      }
    }

    if (isSavedAddress(shipping)) {
      physical_address_attributes = {
        id: shipping.id,
      }
    } else {
      physical_address_attributes = {
        address_one: shipping.address,
        address_two: shipping.address_2,
        city: shipping.city,
        region_name: shipping.state,
        country_id: shipping.country,
        postal_code: shipping.zip,
      }
    }

    if (globalThis.Checkout.utils.hasPhysicalProducts()) {
      const billingSameAsShipping = globalThis.Checkout.store.billingSameAsShipping.get()
      if (globalThis.Checkout.utils.skipBillingAddress(globalThis.Checkout.store)) {
        return { shipping: { shipping_addresses_attributes: [{ physical_address_attributes }] } }
      }
      if (mode == globalThis.Checkout.CheckoutStates.OTO) {
        return {
          shipping: { shipping_addresses_attributes: [{ physical_address_attributes }] },
          billing: { billing_address_attributes, billing_same_as_shipping: false },
        }
      }
      if (billingSameAsShipping) {
        return {
          shipping: { shipping_addresses_attributes: [{ physical_address_attributes }] },
          billing: { billing_same_as_shipping: true },
        }
      } else {
        return {
          shipping: { shipping_addresses_attributes: [{ physical_address_attributes }] },
          billing: { billing_address_attributes, billing_same_as_shipping: false },
        }
      }
    } else {
      if (globalThis.Checkout.utils.skipBillingAddress(globalThis.Checkout.store)) return
      return { billing: { billing_address_attributes } }
    }
  }

  static async #submitOrderAsync(
    data: SubmitPayload,
    maxRetries = 3,
    onBeforeSubmit: () => void,
    onRetryAfter: (sleepTime: number) => void
  ): Promise<Response> {
    let response
    for (let i = 0; i < maxRetries; i++) {
      onBeforeSubmit()
      response = await fetch(renderedHref(), {
        credentials: 'same-origin',
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'X-CF2-POST-TYPE': 'submit',
        },
      }).then((res) => {
        if (res.status >= 500) {
          console.error(res)
          throw Error('500 error')
        }
        return res
      })

      if (response.status === 429) {
        const sleepTime = parseInt(response.headers.get('Retry-After'))
        onRetryAfter(sleepTime)
        console.log(`Waiting on queue, retrying after ${sleepTime}`)
        await sleepMs(sleepTime)
      } else {
        break
      }
    }
    return response
  }

  static async #handleFormSubmitRedirect(response: Response, urlToRedirect: string): Promise<void> {
    const userRedirectSignInUrl = response.headers.get('X-CF2-USER-REDIRECT-URL')
    const signInToken = response.headers.get('X-CF2-USER-SIGN-IN-TOKEN')
    if (userRedirectSignInUrl && signInToken && globalThis.straightforward_onboarding_flow_enabled) {
      const shouldRedirectImmediately = globalThis.straightforward_onboarding_flow_redirect_url
      if (shouldRedirectImmediately) {
        globalThis.location.href = userRedirectSignInUrl
      } else {
        const MAX_WAIT_TIME = 120
        let fetchedActiveWorkspace

        const startTime = Date.now()
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const currentTime = Date.now()
          const elapsedTime = (currentTime - startTime) / 1000
          if (elapsedTime > MAX_WAIT_TIME) {
            fetchedActiveWorkspace = false
            break
          }
          const meRequest = `https://accounts.${globalThis.cfRootDomain}/me.json?token=${encodeURIComponent(
            signInToken
          )}`
          const response = await fetch(meRequest)
          if (response.ok) {
            const responseJson = await response.json()
            const team = responseJson.teams[0]
            const workspaceCreationStatus = team?.workspaces[0]?.creation_status
            if (team?.subscription_status == 'active' && workspaceCreationStatus == 'install_finalized') {
              globalThis.location.href = userRedirectSignInUrl
              fetchedActiveWorkspace = true
              break
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 4000))
        }
        if (!fetchedActiveWorkspace) globalThis.location.href = urlToRedirect
      }
    } else {
      globalThis.location.href = urlToRedirect
    }
  }

  static #add3dsListener(): void {
    if (this.#threedsListenerEnabled) return
    this.#threedsListenerEnabled = true

    globalThis.addEventListener('message', (event) => {
      if (event.data.sender == 'CfOrderStatus') {
        const orderDetails = event.data.details

        if (this.#redirecTo) {
          orderDetails.redirect_to = this.#redirecTo
        }

        if (globalThis.straightforward_onboarding_flow_redirect_url) {
          orderDetails.straightforward_onboarding_flow_redirect_url =
            globalThis.straightforward_onboarding_flow_redirect_url
        }

        fetch(renderedHref(), {
          credentials: 'same-origin',
          method: 'post',
          body: JSON.stringify(orderDetails),
          headers: {
            'Content-Type': 'application/json',
            'X-CF2-POST-TYPE': 'submit',
          },
        }).then((response) => {
          if (orderDetails['orderResult'] == 'declined') {
            globalThis.Checkout.store.submitting.set({
              state: globalThis.Checkout.SubmittingStates.ERROR,
              code: globalThis.Checkout.ErrorTypes.THREEDS_DECLINED_ERROR,
            })
          } else {
            if (response.ok) {
              globalThis.Checkout.store.state.set(globalThis.Checkout.StoreStates.SUBMITTED)
              globalThis.CFDispatchEvent(globalThis.CFEvents.FORM_SUBMITTED_FINALIZED, this.#formSubmitPayload)
              this.#handleFormSubmitRedirect(response, response.headers.get('Location'))
            } else {
              response.json().then((r) => {
                globalThis.Checkout.store.submitting.set({
                  state: globalThis.Checkout.SubmittingStates.ERROR,
                  code: globalThis.Checkout.ErrorTypes.THREEDS_DECLINED_CUSTOM_ERROR,
                  message: r.error,
                })
              })
            }
          }
          globalThis.Checkout.store.threeds.set({
            show: false,
            approvalUrl: null,
          })
        })
      }
    })
  }
}
