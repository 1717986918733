import { atom, map, computed, onMount } from 'nanostores'
import { CheckoutExistingOrders } from './checkout-existing-orders'
import { backfillAddressesId } from '../Utils/checkout-address'
import { Address } from '../types'
import { Cart } from '../../../Cart'

type PaymentMethod = {
  id: number
  type: string
  pretty_type: string
  brand: string
  details: string
  icon: string
  live_mode: boolean
}

type OTPSource = 'email' | 'phone_number'

type ContactData = {
  email: string
  first_name: string
  last_name: string
  phone_number: string
  uuid: string
  is_phone_verified: boolean
  is_sms_enabled: boolean
  masked_phone: string
  payment_methods: PaymentMethod[]
  billing_addresses: Address[]
  shipping_addresses: Address[]
}

// One-Click Checkout Flow
enum AuthMode {
  Initializing,
  AlreadySavedMode,
  AwaitingEmail,
  FetchingEmailCheck,
  AwaitingOtp,
  FetchingOtpValidation,
  InvalidOtp,
  ValidOtp,
  FetchingResendOtp,
  FailedResendOtp,
  TryingAutoPhoneVerification,
  SuggestingPhoneVerification,
  RequestingPhoneVerification,
  RequestingAnotherPhoneVerificationCode,
  SentPhoneVerificationOtp,
  FailedRequestPhoneVerification,
  SubmittingPhoneVerificationCode,
  PhoneVerificationSubmitError,
  PhoneVerificationSucceeded,
  FailedPhoneVerification,
  ContinuingAsSaved,
  ContinuingAsGuest,
}

const PhoneVerificationOtpStates: Set<AuthMode> = new Set([
  AuthMode.RequestingAnotherPhoneVerificationCode,
  AuthMode.SubmittingPhoneVerificationCode,
  AuthMode.PhoneVerificationSubmitError,
  AuthMode.SentPhoneVerificationOtp,
  AuthMode.FailedPhoneVerification,
])

const PhoneVerificationRequestStates: Set<AuthMode> = new Set([
  AuthMode.SuggestingPhoneVerification,
  AuthMode.RequestingPhoneVerification,
  AuthMode.FailedRequestPhoneVerification,
])

const PhoneVerificationDialogStates: Set<AuthMode> = new Set(
  [AuthMode.PhoneVerificationSucceeded]
    .concat([...PhoneVerificationRequestStates])
    .concat([...PhoneVerificationOtpStates])
)

// states that require OTP login dialog be displayed
const AuthDialogStates: Set<AuthMode> = new Set([
  AuthMode.AwaitingOtp,
  AuthMode.FetchingOtpValidation,
  AuthMode.InvalidOtp,
  AuthMode.FetchingResendOtp,
  AuthMode.FailedResendOtp,
  // AuthMode.TryingAutoPhoneVerification,
  ...PhoneVerificationDialogStates,
])

type Event =
  | 'submitOtp'
  | 'resendOtp'
  | 'changeOtpSource'
  | 'continueAsGuest'
  | 'alreadySaved'
  | 'noUser'
  | 'gotEmail'
  | 'sentOtp'
  | 'unrecognized'
  | 'validOtp'
  | 'invalidOtp'
  | 'continueAsSaved'
  | 'suggestPhoneVerification'
  | 'resendFailed'
  | 'skipPhoneVerification'
  | 'requestPhoneVerification'
  | 'sentPhoneVerificationOtp'
  | 'failStartPhoneVerification'
  | 'submitPhoneVerificationCode'
  | 'verificationSucceeded'
  | 'verificationFailed'
  | 'verificationSubmitError'

type FlowChange = { event: Event; from: AuthMode; to: AuthMode; data: any }

const AuthFlow: Partial<Record<AuthMode | '*', Partial<Record<Event, AuthMode>>>> = {
  ['*']: {
    submitOtp: AuthMode.FetchingOtpValidation,
    resendOtp: AuthMode.FetchingResendOtp,
    changeOtpSource: AuthMode.FetchingResendOtp,
    continueAsGuest: AuthMode.ContinuingAsGuest,
  },
  [AuthMode.Initializing]: {
    alreadySaved: AuthMode.AlreadySavedMode,
    noUser: AuthMode.AwaitingEmail,
  },
  [AuthMode.AwaitingEmail]: {
    gotEmail: AuthMode.FetchingEmailCheck,
  },
  [AuthMode.FetchingEmailCheck]: {
    sentOtp: AuthMode.AwaitingOtp,
    unrecognized: AuthMode.ContinuingAsGuest,
  },
  [AuthMode.AwaitingOtp]: {
    submitOtp: AuthMode.FetchingOtpValidation,
    resendOtp: AuthMode.FetchingResendOtp,
  },
  [AuthMode.FetchingOtpValidation]: {
    validOtp: AuthMode.ValidOtp,
    invalidOtp: AuthMode.InvalidOtp,
  },
  [AuthMode.ValidOtp]: {
    continueAsSaved: AuthMode.ContinuingAsSaved,
    suggestPhoneVerification: AuthMode.SuggestingPhoneVerification,
  },
  [AuthMode.InvalidOtp]: {
    resendOtp: AuthMode.FetchingResendOtp,
  },
  [AuthMode.FetchingResendOtp]: {
    sentOtp: AuthMode.AwaitingOtp,
    resendFailed: AuthMode.FailedResendOtp,
  },
  [AuthMode.FailedResendOtp]: {},
  // phone verification flow
  [AuthMode.SuggestingPhoneVerification]: {
    skipPhoneVerification: AuthMode.ContinuingAsSaved,
    requestPhoneVerification: AuthMode.RequestingPhoneVerification,
  },
  [AuthMode.RequestingPhoneVerification]: {
    skipPhoneVerification: AuthMode.ContinuingAsSaved,
    sentPhoneVerificationOtp: AuthMode.SentPhoneVerificationOtp,
    failStartPhoneVerification: AuthMode.FailedRequestPhoneVerification,
    requestPhoneVerification: AuthMode.RequestingPhoneVerification,
  },
  [AuthMode.FailedRequestPhoneVerification]: {
    skipPhoneVerification: AuthMode.ContinuingAsSaved,
    requestPhoneVerification: AuthMode.RequestingPhoneVerification,
  },
  [AuthMode.RequestingAnotherPhoneVerificationCode]: {
    skipPhoneVerification: AuthMode.ContinuingAsSaved,
    sentPhoneVerificationOtp: AuthMode.SentPhoneVerificationOtp,
    failStartPhoneVerification: AuthMode.FailedRequestPhoneVerification,
    requestPhoneVerification: AuthMode.RequestingAnotherPhoneVerificationCode,
  },
  [AuthMode.SentPhoneVerificationOtp]: {
    skipPhoneVerification: AuthMode.ContinuingAsSaved,
    requestPhoneVerification: AuthMode.RequestingAnotherPhoneVerificationCode,
    submitPhoneVerificationCode: AuthMode.SubmittingPhoneVerificationCode,
  },
  [AuthMode.SubmittingPhoneVerificationCode]: {
    skipPhoneVerification: AuthMode.ContinuingAsSaved,
    verificationSucceeded: AuthMode.PhoneVerificationSucceeded,
    verificationFailed: AuthMode.FailedPhoneVerification,
    verificationSubmitError: AuthMode.PhoneVerificationSubmitError,
  },
  [AuthMode.FailedPhoneVerification]: {
    skipPhoneVerification: AuthMode.ContinuingAsSaved,
    submitPhoneVerificationCode: AuthMode.SubmittingPhoneVerificationCode,
    requestPhoneVerification: AuthMode.RequestingAnotherPhoneVerificationCode,
  },
  [AuthMode.PhoneVerificationSucceeded]: {
    continueAsSaved: AuthMode.ContinuingAsSaved,
  },
}

export class CheckoutAuth {
  static store
  static computed
  static initializeOneClickCheckoutFlow(): void {
    globalThis.Checkout.auth = this
    // store

    this.store = globalThis.Checkout.store.auth = {
      flow: map({
        from: undefined,
        event: undefined,
        to: AuthMode.Initializing,
      }),
      otpRequestFailure: map({
        time: undefined,
        retry_in: 0,
        error: undefined,
      }),
      otpLoginSource: atom(undefined),
      phoneVerificationRequest: map({
        time: undefined,
        count: 0,
        rejections: 0,
        error: undefined,
      }),
      phoneVerificationOtp: map({
        time: undefined,
        count: 0,
        rejections: 0,
        error: undefined,
      }),
    }
    this.store.mode = computed(this.store.flow, (change) => change.to)

    const requireLogin = computed([this.store.mode], (mode) => AuthDialogStates.has(mode)),
      otpLoginOptions = computed(globalThis.Checkout.store.contact_pending_auth, (partialContact) => {
        const options = []
        if (partialContact) {
          if (partialContact.is_phone_verified === true) {
            options.push('phone_number')
          }
          if (partialContact.email) {
            options.push('email')
          }
        }
        return options
      }),
      suggestingPhoneVerification = computed([this.store.mode], (mode) => PhoneVerificationDialogStates.has(mode)),
      showingPhoneVerificationRequest = computed([this.store.mode], (mode) => PhoneVerificationRequestStates.has(mode)),
      showingPhoneOtp = computed([this.store.mode], (mode) => PhoneVerificationOtpStates.has(mode)),
      validGuestEmail = computed(
        [
          globalThis.Checkout.store.checkout.mode,
          globalThis.Checkout.store.contact,
          globalThis.Checkout.computed.contactErrors,
          globalThis.Checkout.computed.hideContactInformationForm,
        ],
        (mode, { email }, errors, hideContactInformationForm) =>
          mode === 'guest' && !errors?.fields?.email && !hideContactInformationForm && email
      ),
      // retrySeconds = $retrySeconds,
      submittingPhoneVerification = computed(
        globalThis.Checkout.store.auth.mode,
        (mode) => mode === AuthMode.SubmittingPhoneVerificationCode
      ),
      RequestingPhoneVerification = computed(
        globalThis.Checkout.store.auth.mode,
        (mode) => mode === AuthMode.RequestingPhoneVerification
      ),
      phoneVerificationMessages = computed(globalThis.Checkout.store.auth.mode, (mode) =>
        mode === AuthMode.RequestingPhoneVerification
          ? 'Requesting a new code...'
          : // // TODO
            // mode === AuthMode.SentAnotherPhoneVerificationOtp ?
            //   "Resent code. Please check your messages." :
            ''
      ),
      phoneVerificationRequestErrorMsg = computed(globalThis.Checkout.store.auth.mode, (mode) => {
        if ([AuthMode.FailedRequestPhoneVerification].includes(mode)) {
          const { numberTaken, errors } = globalThis.Checkout.store.auth.flow.get().data
          if (numberTaken) {
            return 'Sorry, that phone number is already taken'
          }
          if (errors) {
            // this should not usually happen
            return `Error: ${errors}`
          }
          // There may be a network connection error
          return 'Unknown error. Please check your connection and try again.'
        }
        return ''
      }),
      phoneVerificationErrorMsg = computed(globalThis.Checkout.store.auth.mode, (mode) => {
        if (mode === AuthMode.FailedPhoneVerification) {
          return 'Invalid code. Please check and try again.'
        }
        if ([AuthMode.PhoneVerificationSubmitError].includes(mode)) {
          return 'Unknown error. Please check your connection and try again.'
        }
        return ''
      }),
      //showingPhoneVerificationRequest = computed([this.store.mode], mode => PhoneVerificationRequestStates.has(mode)),
      submittingOtp = computed(globalThis.Checkout.store.auth.mode, (mode) => mode === AuthMode.FetchingOtpValidation),
      requestingAnotherOtp = computed(globalThis.Checkout.store.auth.mode, (mode) => {
        return mode === AuthMode.FetchingResendOtp
      }),
      resentOtp = atom(false),
      otpRequestError = computed(globalThis.Checkout.store.auth.otpRequestFailure, ({ error }) => error)
    otpLoginOptions.subscribe((options) => {
      if (options && this.store.otpLoginSource.get() === undefined) {
        this.store.otpLoginSource.set(options[0])
      }
    })
    // computed retry time
    const retrySeconds = atom(0)
    onMount(retrySeconds, () => {
      let ticking
      const unsub = this.store.otpRequestFailure.subscribe(({ time, retry_in }) => {
        stop()
        if (!retry_in) return
        const t = new Date()
        t.setSeconds(t.getSeconds() + retry_in)

        const update = () => {
          const now = Date.now()
          let diff = t.getTime() - now
          if (diff <= 0) {
            diff = 0
            stop()
          }
          retrySeconds.set(Math.ceil(diff / 1000))
        }
        update()
        ticking = setInterval(update, 1000)
      })
      return () => {
        unsub()
        stop()
      }
      function stop() {
        if (ticking) {
          clearInterval(ticking)
          ticking = undefined
        }
      }
    })

    this.computed = globalThis.Checkout.computed.auth = {
      validGuestEmail,
      requireLogin,
      otpLoginOptions,
      showingPhoneOtp,
      submittingOtp,
      requestingAnotherOtp,
      retrySeconds,
      resentOtp,
      otpRequestError,
      suggestingPhoneVerification,
      showingPhoneVerificationRequest,
      submittingPhoneVerification,
      RequestingPhoneVerification,
      phoneVerificationRequestErrorMsg,
      phoneVerificationMessages,
      phoneVerificationErrorMsg,
    }
    // listeners
    globalThis.Checkout.store.checkout.mode.listen((mode) => {
      if (mode === 'guest') {
        this.continueAsGuest()
        this.fetchSignOff().catch((error) => {
          console.log('error signing off', error)
        })
      }
    })
    this.store.flow.listen((change) => {
      this.onFlowChange(change)
    })
    const uniqueEmailsFound = new Set()
    this.computed.validGuestEmail.subscribe((email) => {
      if (!email || uniqueEmailsFound.has(email)) return
      uniqueEmailsFound.add(email)
      this.send('gotEmail')
      this.fetchAuthentication(email)
    })
    // determine next state based on whether already in saved mode
    if (globalThis.Checkout.store.checkout.mode.get() === 'saved') {
      this.send('alreadySaved')
    } else {
      this.send('noUser')
    }
  }
  static onFlowChange({ to, data }: FlowChange): void {
    // when valid otp, auto-transition depending on source
    if (to === AuthMode.ValidOtp) {
      const { source } = data
      const lacksVerifiedPhone =
        source === 'email' &&
        globalThis.Checkout.store.contact_pending_auth.get().is_phone_verified !== true &&
        !sessionStorage.getItem('skipped-phone-verification')
      const isSmsEnabled = globalThis.Checkout.store.contact_pending_auth.get().is_sms_enabled
      const nextEvent = isSmsEnabled && lacksVerifiedPhone ? 'suggestPhoneVerification' : 'continueAsSaved'
      Promise.resolve().then(() => this.send(nextEvent))
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static send(eventId: Event, data?: any): void {
    const modeName = this.store.mode.get()
    const modeConfig = AuthFlow[modeName]
    const nextMode = (modeConfig && modeConfig[eventId]) || AuthFlow['*'][eventId]
    if (nextMode) {
      // nextMode = this.beforeSend(eventId, payload, nextMode) ?? nextMode
      this.store.flow.set({
        from: this.store.mode.get(),
        event: eventId,
        to: nextMode,
        data,
      })
      // this.store.mode.set(nextMode)
    }
  }
  static requestPhoneVerification(): void {
    const count = globalThis.Checkout.auth.store.phoneVerificationRequest.get().count ?? 0
    globalThis.Checkout.auth.store.phoneVerificationRequest.setKey('count', count + 1)
    this.fetchAddVerificationNumber()
      .then((result) => {
        if (result.ok) {
          globalThis.Checkout.auth.store.phoneVerificationRequest.setKey('error', undefined)
          this.send('sentPhoneVerificationOtp')
        } else {
          const count = globalThis.Checkout.auth.store.phoneVerificationRequest.get().rejections ?? 0
          globalThis.Checkout.auth.store.phoneVerificationRequest.setKey('rejections', count + 1)
          this.send('failStartPhoneVerification', result)
        }
      })
      .catch((err) => {
        console.log('request phone verification failed', err)
        globalThis.Checkout.auth.store.phoneVerificationRequest.setKey('error', err)
        this.send('failStartPhoneVerification')
      })
    this.send('requestPhoneVerification')
  }

  /*
      /user_pages/api/v1/contacts/request_authentication.json
      Param: email
      Response:
      📌 400: {result: false, errors: 'Invalid email'}
      📌 404 (contact not found): {result: false}
      ✅ 200: {result: true, contact: 
          {first_name: '', email: '', uuid: '', masked_phone: '', is_verified: '', is_phone_verified: '' }}
    */
  static fetchAuthentication(email: string): void {
    fetch('/user_pages/api/v1/contacts/request_authentication.json?' + new URLSearchParams({ email }), {
      method: 'GET',
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then(({ result, contact }) => {
            const sentOTP = !!result
            if (sentOTP) {
              globalThis.Checkout.store.contact_pending_auth.set({ ...(contact ?? {}), authenticated: false })
              this.send('sentOtp')
            }
          })
        }
      })
      .catch((err) => {
        // currently if this background operation fails,
        // we just silently quit because user does not care
        // perhaps add retry/backoff logic here
        console.log('fetch exception', err)
      })
  }
  static submitOtp(code: string): void {
    this.send('submitOtp')
    this.fetchValidateCode(code)
  }
  static resendCode(optionalSource: OTPSource): Promise<void> {
    this.send('resendOtp', { optionalSource })
    return this.fetchResendCode(optionalSource)
  }
  static fetchResendCode(optionalSource: OTPSource): Promise<void> {
    const { email } = globalThis.Checkout.store.contact_pending_auth.get()
    const source = optionalSource || globalThis.Checkout.auth.store.otpLoginSource.get()
    this.store.otpRequestFailure.set({
      time: -1,
      retry_in: 0,
    })
    return fetch('/user_pages/api/v1/contacts/resend_otp.json', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        source: optionalSource || source,
        email,
      }),
    })
      .then((res) => {
        if (res.ok) {
          globalThis.Checkout.auth.store.otpLoginSource.set(source)
        }
        return res
      })
      .then((res) => this.fetchedResendCode(res))
      .catch((err) => {
        this.fetchedResendCode(undefined, err)
      })
  }
  static fetchedResendCode(res: Response, error?: Error): Promise<void> {
    let sent
    const fail = ({ retry_in, error }: { retry_in?: number; error?: Error } = {}) => {
      const time = new Date()
      this.store.otpRequestFailure.set({
        time,
        retry_in,
        error,
      })
      this.send('resendFailed', { retry_in, time, error })
    }
    if (res) {
      if (res.ok) {
        return res.json().then((props) => {
          const { result } = props
          sent = result
          if (sent) {
            this.send('sentOtp')
          } else {
            fail()
          }
        })
      } else {
        if (res.status === 400) {
          return res
            .json()
            .then((props) => {
              const { retry_in } = props
              fail({ retry_in })
            })
            .catch(fail)
        }
      }
    }
    fail({ error })
  }
  /*
      PATCH: /user_pages/api/v1/contacts/validate_and_sign_in.json
        2a. Param: {otp: '', email: '', source: 'email' | 'phone_number' }
        2b. Response
      404 (contact not found): {result: false}
      400 (invalid OTP): {result: false}
      200: {result: true, contact: {first_name: ‘’, email: ‘’, uuid: ‘’, masked_phone: ‘’,
        is_verified: ‘’, is_phone_verified: ‘’, payment_methods: [], shipping_addresses: [], billing_addresses: [] }}
    */
  static fetchValidateCode(otp: string): void {
    const { email } = globalThis.Checkout.store.contact_pending_auth.get()
    const source = globalThis.Checkout.auth.store.otpLoginSource.get()
    fetch('/user_pages/api/v1/contacts/validate_and_sign_in', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        otp,
        source,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            const { result, contact } = data
            if (result) {
              this.backfillContactFields(contact)
              CheckoutExistingOrders.fetch().then(() => {
                const isSelectedCartItemUpdatable = Cart.stores.cartData.get().items.some((i) => i.type)
                if (isSelectedCartItemUpdatable) {
                  globalThis.Checkout.store.checkout.lastModeIndependentOfCartItems.set('saved')
                } else {
                  globalThis.Checkout.store.checkout.mode.set('saved')
                }

                this.send('validOtp', { source })
              })
            } else {
              this.otpFailed()
            }
          })
        } else {
          this.otpFailed()
        }
      })
      // Update the state with the received response
      .catch((err) => {
        console.log('fetch auth exception', err)
        this.otpFailed()
      })
  }
  static fetchAddVerificationNumber(): Promise<Response | { result?: boolean; ok?: boolean; errors }> {
    console.log('fetchAddVerificationNumber')
    const { email, phone_number } = globalThis.Checkout.store.contact.get()
    return fetch('/user_pages/api/v1/contacts/request_otp_to_add_new_number', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        phone_number,
      }),
    }).then((res) => {
      if (res.ok) {
        return res
      }
      return res.json().then(({ result, errors }) => {
        const errorResult = {
          ok: false,
          errors,
          numberTaken: errors === 'Phone number already taken',
        }
        return errorResult
      })
    })
  }
  static submitVerifyPhoneCode(otp: string): Promise<void> {
    const { email, phone_number } = globalThis.Checkout.store.contact.get()
    this.send('submitPhoneVerificationCode')
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const fail = (err) => {}
    return fetch('/user_pages/api/v1/contacts/validate_and_associate_phone_number', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      body: JSON.stringify({
        email,
        phone_number,
        otp,
      }),
    })
      .then((res) => {
        if (res.ok) {
          this.send('verificationSucceeded')
        } else {
          this.send('verificationFailed')
        }
      })
      .catch((err) => {
        console.log('fetch auth exception', err)
        this.send('verificationSubmitError', { error: err })
      })
  }

  static continueAsGuest(): void {
    this.send('continueAsGuest')
  }

  static fetchSignOff(): Promise<any> {
    // fire and forget sign_out
    return fetch('/contacts/sign_out', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
    }).then((res) => {
      if (!res.ok) {
        console.log('sign_out failed', res)
        throw new Error('Sign out failed')
      }
      return res
    })
  }

  static otpFailed(): void {
    this.send('invalidOtp')
  }

  static backfillContactFields(data: ContactData): void {
    const {
      email,
      first_name,
      last_name,
      phone_number,
      uuid,
      is_phone_verified,
      is_sms_enabled,
      masked_phone,
      payment_methods,
      billing_addresses,
      shipping_addresses,
    } = data

    let newBilling: Address | Record<string, never> = {}
    if (billing_addresses && billing_addresses.length > 0) {
      const backfilled_billing_addresses = backfillAddressesId(billing_addresses)
      newBilling = backfilled_billing_addresses[0]
      globalThis.Checkout.store.billing.set(newBilling)
      globalThis.Checkout.store.billing_addresses.set(backfilled_billing_addresses)
    } else {
      globalThis.Checkout.store.billing_addresses.set([])
    }

    let newShipping: Address | Record<string, never> = {}
    if (shipping_addresses && shipping_addresses.length > 0) {
      const backfilled_shipping_addresses = backfillAddressesId(shipping_addresses)
      newShipping = backfilled_shipping_addresses[0]
      globalThis.Checkout.store.shipping.set(newShipping)
      globalThis.Checkout.store.shipping_addresses.set(backfilled_shipping_addresses)
    } else {
      globalThis.Checkout.store.shipping_addresses.set([])
    }

    globalThis.Checkout.store.billingSameAsShipping.set(Boolean(newBilling.id && newBilling.id == newShipping.id))

    if (payment_methods && payment_methods.length > 0) {
      globalThis.Checkout.store.paymentMethods.set([
        ...(globalThis.Checkout.store.paymentMethods.get() || []),
        ...payment_methods,
      ])
      if (!globalThis.Checkout.store.payment.id.get()) {
        globalThis.Checkout.store.payment.id.set(payment_methods[0].id)
      }

      globalThis.Checkout.store.contact.set({
        ...globalThis.Checkout.store.contact.get(),
        email,
        first_name,
        last_name,
        phone_number,
      })

      globalThis.Checkout.store.contact_pending_auth.set({
        ...globalThis.Checkout.store.contact.get(),
        email,
        first_name,
        last_name,
        phone_number,
        uuid,
        is_phone_verified,
        is_sms_enabled,
        masked_phone,
        authenticated: true,
      })
    }
  }
  static skipPhoneVerification(): void {
    sessionStorage.setItem('skipped-phone-verification', 'true')
    this.send('skipPhoneVerification')
  }
  static continueAfterPhoneVerification(): void {
    this.send('continueAsSaved')
  }
}
