
          
          
          import { validateValue } from '@yggdrasil/Checkout/V2/Utils/checkout-input-validator'
  import { getSSRGlobalResourceData } from "@yggdrasil/Checkout/V2/Services/checkout-data-loader"
  import { Cart } from '@yggdrasil/Cart'
  const CheckoutStates = {
    OTO: 'oto',
    GUEST: 'guest',
    SAVED: 'saved',
    UPGRADE_DOWNGRADE: 'upgradeDowngrade',
    REACTIVATE: 'reactivate',
  }

  const StoreStates = {
    START: 'start',
    INITIALIZING: 'initializing',
    INITIALIZED: 'initialized',
    FILLING_FORM: 'filling-form',
    SUBMITTING: 'submitting',
    SUBMITTED: 'submitted',
  }

  const SummaryStates = {
    WAITING: 'waiting',
    CALCULATING: 'calculating',
    ERROR: 'error',
    OK: 'ok',
  }

  const CouponStates = {
    READY: 'ready',
    APPLYING: 'applying',
    APPLIED: 'applied',
    ERROR: 'error',
  }

  const PaymentStates = {
    START: 'start',
    INITIALIZING: 'initializing',
    LOADING: 'loading',
    INITIALIZED: 'initialized',
  }

  const SubmittingStates = {
    IDLE: 'idle',
    START: 'start',
    WAITING_ON_QUEUE: 'waiting-on-queue',
    ERROR: 'error',
    DONE: 'done',
  }

  const PaypalStates = {
    IDLE: 'idle',
    INITIALIZED: 'initialized',
    ERROR: 'error',
    ADDING_PAYMENT_METHOD: 'adding-payment-method',
    PAYMENT_METHOD_APPROVED: 'payment-method-approved',
  }

  const ErrorTypes = {
    REBILLY_ERROR: 'rebilly-error',
    SERVER_ERROR: 'server-error',
    UNHANDLED_SERVER_RESPONSE: 'unhandled-server-response',
    EXCEEDED_MAX_RETRIES: 'exceeded-max-retries',
    THREEDS_DECLINED_ERROR: '3ds-declined-error',
    THREEDS_DECLINED_CUSTOM_ERROR: '3ds-declined-custom-error',
    PAYPAL_DECLINED_ERROR: 'paypal-declined-error',
    PAYPAL_CUSTOM_ERROR: 'paypal-custom-error',
  }

  const states = {
    StoreStates,
    SummaryStates,
    PaymentStates,
    SubmittingStates,
    PaypalStates,
    CouponStates,
    ErrorTypes,
    CheckoutStates,
  }
  const globals = getSSRGlobalResourceData()
  const ssrDynamicData = globalThis.getSSRDynamicStoreData(globals.products)
  
  window.Checkout = {
    ...states,
    ...globals,
    ssrDynamicData,
    utils: buildUtils(),
  }

  window.addEventListener('keydown',function(e) {
    if (e.keyIdentifier=='U+000A' || e.keyIdentifier=='Enter' || e.keyCode==13) {
      if (Checkout.store?.payment.type.get() == 'apple-pay') {
        e.preventDefault();
        return false;
      }
    }
  }, true);


  // TODO(henrique): <ONE_STORE_PER_CHECKOUT> we dont wanna use Checkout.store in this method.
  // Instead we wanna store be passed as a parameter to the function utility
  function buildUtils() {
    const hasErrors = (errors) => (errors?.globalErrors ?? []).length != 0 || Object.keys(errors?.fields ?? {}).length != 0
    const productErrors = (cart, showAllErrors) => {
      const errors = {
        globalErrors: [],
        fields: {},
      }
      if (showAllErrors) {
        const hasProducts = cart.items
          .filter((p) => !Checkout.productsById[p.product_id].bump)
          .some((item) => item.quantity > 0)

        if (!hasProducts) {
          errors.globalErrors.push({ message: 'At least one product should be selected' })
        }
      }
      return cleanupEmptyErrors(errors)
    }

    const addressErrors = (address, showAllErrors, fields) => {
      const errors = {
        globalErrors: [],
        fields: {},
      }

      fields.forEach((field) => {
        const value = address[field]
        if (value == undefined && !showAllErrors) return

        const { valid, message } = validateValue(field, value)
        if (!valid) {
          errors.fields[field] = { message }
        }
      })
      return cleanupEmptyErrors(errors)
    }

    const hasPhysicalProductsWithParams = (cart) => {
      return cart.items.some(({ product_id, variant_id }) => {
        const product = Checkout.productsById[product_id]
        const variant = Checkout.variantsById[variant_id]
        let productType = variant?.product_type
        productType = productType || product?.product_type
        return productType == 'physical'
      })
    }

    const billingErrors = (billing, billingSameAsShipping, showAllErrors, cart, mode, billingFields, billingApiErrorsByField, paymentType) => {
      if ((hasPhysicalProductsWithParams(cart) && billingSameAsShipping) || mode == Checkout.CheckoutStates.UPGRADE_DOWNGRADE || mode == Checkout.CheckoutStates.OTO || paymentType == 'apple-pay') {
        return null
      } else if (billingApiErrorsByField) {
        return {
          fields: billingApiErrorsByField,
        }
      } else if (mode == Checkout.CheckoutStates.REACTIVATE || mode == Checkout.CheckoutStates.SAVED) {
        const savedBillingAddresses = Checkout.store.billing_addresses.get()
        const isActiveAddressSaved = savedBillingAddresses.find((addr) => addr.id == billing.id)
        if (!isActiveAddressSaved) {
          return addressErrors(billing, showAllErrors, billingFields)
        }
        return null
      } else {
        return addressErrors(billing, showAllErrors, billingFields)
      }
    }

    const shippingErrors = (shipping, showAllErrors, cart, mode, shippingFields, paymentType) => {
      if (!hasPhysicalProductsWithParams(cart) || mode == Checkout.CheckoutStates.UPGRADE_DOWNGRADE || paymentType == 'apple-pay') {
        return null
      } else {
        return addressErrors(shipping, showAllErrors, shippingFields)
      }
    }
    const cleanupEmptyErrors = (errors) => {
      if (errors.globalErrors.length == 0) delete errors.globalErrors
      if (Object.keys(errors.fields).length == 0) delete errors.fields
      
      if (Object.keys(errors).length == 0) return null
      
      return errors
    }

    return {
      productErrors,
      billingErrors,
      shippingErrors,
      addressErrors,
      cleanupEmptyErrors,
      hasErrors,
      hasPhysicalProductsWithParams,
      skipBillingAddress: (store) => {
        const isNotDigitalWalletPayment = store.payment.type.get() != 'apple-pay'
        return isNotDigitalWalletPayment && store.billingFields.get().length == 0
      },
      hasPhysicalProducts: (store) => {
        const cart = Cart.stores.cartData.get()
        return hasPhysicalProductsWithParams(cart)
      },
      hasValidDataForOrderPreview: (options) => {
        const mode = Checkout.store.checkout.mode.get()

        const cart = Cart.stores.cartData.get()
        const billing = Checkout.store.billing.get()
        const shipping = Checkout.store.shipping.get()
        const billingSameAsShipping = Checkout.store.billingSameAsShipping.get()
        const billingApiErrorsByField = Checkout.store.billingApiErrorsByField.get()
        
        const paymentType = Checkout.store.payment.type.get()
        
        const showAllBillingErrors = true
        const showAllShippingErrors = true
        const showAllProductErrors = true
        
        const pErrors = productErrors(cart, showAllProductErrors)
        const bErrors = billingErrors(billing, billingSameAsShipping, showAllBillingErrors, cart, mode, options.billingFields, billingApiErrorsByField, paymentType)
        const sErrors = shippingErrors(shipping, showAllShippingErrors, cart, mode, options.shippingFields, paymentType)
        return !hasErrors(pErrors) && !hasErrors(bErrors) && !hasErrors(sErrors)
      },
      canSubmit: () => {
        const state = Checkout.store.state.get()
        const summary = Checkout.store.summary.get()
        return state == Checkout.StoreStates.FILLING_FORM && summary.state == Checkout.SummaryStates.OK
      },
    }
  }
        